"use client";
import Image from "@components/Image";
import Images from "@services/utils/Images";
import React from "react";
import "../../../app/global.scss";

const howItWorksItems = [
  { title: "Choose an outlet", subtitle: "Enter PNR & explore restaurants for your journey", img: Images.howItWorks1 },
  { title: "Complete Order", subtitle: "Choose your food & schedule your order paying online or COD", img: Images.howItWorks2 },
  { title: "Enjoy Tasty Food", subtitle: "Enjoy your meal delivered to your seat", img: Images.howItWorks3 },
];

const HowItWorks: React.FC = () => {
  return (
    <>
      <h2 className="my-10 text-center text-4xl">How it works</h2>
      <div className="text-md my-5 flex flex-col items-start items-center justify-center justify-center justify-between gap-12 text-center md:flex-row">
        {howItWorksItems.map((item, i) => (
          <div className="flex w-72 flex-col items-center gap-4" key={item.title}>
            <Image src={item.img} alt={item.title} removeBorder />

            <div className={"flex h-9.5 w-max items-center justify-between overflow-hidden rounded border border-alert-primary pr-4"}>
              <span className={"border-cut m-0 mr-2 h-full w-9 bg-brand-ultraLight text-2.5xl font-bold leading-snug text-white"}>{i + 1}</span>
              <span className="text-xl font-bold italic text-gray-60">{item.title}</span>
            </div>

            <p className="max-w-50 text-lg text-gray-60">{item.subtitle}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default HowItWorks;
