import { NextSeo, NextSeoProps } from "next-seo";
import { ReactNode } from "react";
import { ElementId } from "../enums/common";
import FullScreenLoader from "./FullScreenLoader";
import DesktopFooter from "./HeadersFooters/DesktopFooter";

type Props = {
  isMobile: boolean;
  children?: ReactNode;
  bgColor?: string;
  isLoading?: boolean;
  loaderMsg?: string;
  removeFooterMargin?: boolean;
  height?: string;
  seo?: NextSeoProps;
};

const Layout = ({
  isMobile,
  children,
  bgColor = isMobile ? "bg-white" : "bg-gray-5",
  isLoading = false,
  loaderMsg,
  removeFooterMargin,
  height,
  seo,
}: Props) => {
  return (
    <div className={`${bgColor} ${height ?? "h-screen"} overflow-y-auto ${isMobile ? "pb-30" : ""}`} id={ElementId.pageContainer}>
      <NextSeo {...seo} />
      <header></header>
      <div>
        {/* {!isLoading && ( */}
        {/* <> */}
        {children}
        {!isMobile && (
          <div className={`${!removeFooterMargin ? "mt-30" : ""}`}>
            <DesktopFooter />
          </div>
        )}
        {/* </> */}
        {/* )} */}
        <FullScreenLoader show={isLoading}>{!!loaderMsg && <span className="subtitle-2">{loaderMsg}</span>}</FullScreenLoader>
      </div>
      {/*<footer>*/}
      {/*  <hr />*/}
      {/*  <span>I'm here to stay (Footer)</span>*/}
      {/*</footer>*/}
      {/*<BottomNavbar />*/}
      <div id={"toast-container"} className={"fixed bottom-1 left-2/4 z-50 w-max max-w-70 -translate-x-2/4 transform lg:max-w-max"} />
    </div>
  );
};

export default Layout;
