import { logger } from "@services/utils/Logger";

const range = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
const convertBase = (value: string, fromBase: number, toBase: number) => {
  const baseRange = range.split("");
  const fromRange = baseRange.slice(0, fromBase);
  const toRange = baseRange.slice(0, toBase);

  let fromValue = value
    .split("")
    .reverse()
    .reduce((carry, digit, index) => {
      if (fromRange.indexOf(digit) === -1) throw new Error("Invalid digit `" + digit + "` for base " + fromBase + ".");
      return (carry += fromRange.indexOf(digit) * Math.pow(fromBase, index));
    }, 0);

  let toValue = "";
  while (fromValue > 0) {
    toValue = toRange[fromValue % toBase] + toValue;
    fromValue = (fromValue - (fromValue % toBase)) / toBase;
  }
  return toValue || "0";
};

const encryptData = (data: string) => {
  if (typeof btoa !== "undefined") {
    return btoa(data);
  }
  return Buffer.from(data, "binary").toString("base64");
};

const getPosition = (ts: number) => {
  const timestamp = new Date(ts);
  const position = timestamp.getUTCDate() + timestamp.getUTCMonth() + timestamp.getUTCFullYear() - 2000;
  return position < 0 ? 0 : position;
};

const getLength = (ts: number) => {
  const timestamp = new Date(ts);
  return timestamp.getUTCHours() + timestamp.getUTCMinutes();
};

export const randomString = (length = 8) => {
  let str = "";
  for (let i = 0; i < length; i++) {
    str += range.charAt(Math.floor(Math.random() * range.length));
  }
  return str;
};

export const getEncryptedData = (pnr: number, data: any, ts: number, auth: string) => {
  try {
    const authParts = auth.split(".");
    const encryptedData = encryptData(JSON.stringify(data, null, 0));
    const [position, filler] = [getPosition(ts), randomString(getLength(ts))];
    const sign = [convertBase(pnr.toString(), 10, 62), convertBase(ts.toString(), 10, 62)].join("");
    return [
      authParts[0],
      authParts[1],
      [encryptedData.slice(0, position), filler, encryptedData.slice(position)].join(""),
      [authParts[2], sign].join("_"),
    ].join(".");
  } catch (e) {
    logger.error(e);
    return null;
  }
};

export const getDecryptedData = (pnr: number, encryptedData: string) => {
  try {
    const data = encryptedData.split(".");
    const jwtHeader = data[3].split("_");
    const sign = jwtHeader.pop() ?? "";
    if (pnr !== parseInt(convertBase(sign.slice(0, 6), 62, 10))) {
      return {};
    }
    const ts = parseInt(convertBase(sign.slice(6), 62, 10));
    const [position, length] = [getPosition(ts), getLength(ts)];
    const pnrData = JSON.parse(atob([data[2].slice(0, position), data[2].slice(position + length)].join("")));
    const auth = [data[0], data[1], jwtHeader.join("_")].join(".");
    return { pnrData, auth };
  } catch (e) {
    return {};
  }
};
