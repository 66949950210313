import { ApiCookieFlags, ApiResponseStorageKeys } from "@enums/ApiUtils";
import Analytics from "@services/analytics";
import { getDecryptedData } from "@services/common/pnr";
import { Storage } from "@services/utils/Storage";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { CurrentPnrInfo, PnrContextType } from "../context";
import { ErrorMessages } from "../enums/messages";
import { PantryInfo, PnrResponse, PnrResponseData, StationInfo, TrainInfo } from "../interfaces";
import { getPnrInfo } from "../services/utils/Common";
import useMounted from "./useMounted";
import usePnrContext from "./usePnrContext";
import { handleServerClientData } from "./useServerClientData";

type Props = {
  invalidPnrCb?: (errorMsg: string) => void;
  validPnrCb?: (stations: StationInfo[], pnr: string, trainInfo: TrainInfo, pantryInfo: PantryInfo) => void;
  retrievePnrInfoOnMountIfNeeded?: boolean;
  token?: string;
};

type ReturnType = {
  pnrInfo: CurrentPnrInfo;
  retrievePnrInfo: (pnr: string) => void;
  setPnrInfo: PnrContextType["setPnrInfo"];
};

// retrievePnrInfoOnMountIfNeeded has to be set to true only in pages
// because if components within the page use this hook, api call would be made multiple times
// when pnr info is missing
const useCurrentPnrInfo = ({ invalidPnrCb, validPnrCb, retrievePnrInfoOnMountIfNeeded = false, token }: Props = {}): ReturnType => {
  const { query } = useRouter();
  const pnrContextInfo = usePnrContext();
  const mounted = useMounted();
  /* different use cases
    1. PNR info is expired- > covered
    2. if the pnr info is stale from server side -> wll handle later TODO: @saurabh @indra
    3. access token expires and apis starts failing -> Done
    4. somehow api throws 401 we should set some retry limit -> Done
*/

  const handlePnrData = (pnr: string, data: PnrResponse) => {
    if (data?.status === "success") {
      if (!!data.result?.stations?.length || !!data.result?.pantryInfo?.isPantryAvailable) {
        // only set pnr in storage when there're stations
        pnrContextInfo.setPnrInfo?.({ pnr, pnrResponse: data["result"] });
        Analytics.moengageAttribute()?.last_searched_pnr(pnr);
      }
      validPnrCb && validPnrCb(data.result.stations, pnr, data.result.trainInfo, data.result.pantryInfo);
    } else {
      invalidPnrCb && invalidPnrCb(data.message);
    }
  };

  const retrievePnrInfo = (pnr: string) => {
    if (!!token) {
      const { pnrData, auth } = getDecryptedData(parseInt(pnr), token);
      if (!!pnrData && !!auth) {
        handleServerClientData({
          isServerDataAvailable: true,
          serverData: pnrData,
          cookieFlag: ApiCookieFlags.pnrData(pnr),
          storageKey: ApiResponseStorageKeys.pnrData(pnr),
          storageTtl: 5 * 60 * 1000,
        });
        handlePnrData(pnr, pnrData as PnrResponse);
        Storage.set("auth", auth);
        return;
      }
    }
    const data = handleServerClientData({
      storageKey: ApiResponseStorageKeys.pnrData(pnr),
      checkDataAv: (result: PnrResponseData) => !!result?.stations?.length,
    });
    console.log("handleServerClientData res", data);
    if (data?.status === "success") {
      console.log("got pnr from storage");
      handlePnrData(pnr, data as PnrResponse);
      return;
    }
    getPnrInfo(pnr)
      .then((data) => {
        handlePnrData(pnr, data as PnrResponse);
      })
      .catch((e) => {
        invalidPnrCb && invalidPnrCb(ErrorMessages.TRY_AGAIN);
        return undefined;
      });
  };

  useEffect(() => {
    if (!pnrContextInfo.pnrInfo.pnr && !pnrContextInfo.pnrInfo.trainInfo && !!query.pnr && mounted && retrievePnrInfoOnMountIfNeeded) {
      // console.log('test', pnrContextInfo, query.pnr)
      retrievePnrInfo(query.pnr as string);
    }
  }, [mounted, retrievePnrInfoOnMountIfNeeded]);

  const pnrInfo = useMemo(() => pnrContextInfo.pnrInfo, [pnrContextInfo.pnrInfo]);

  return { pnrInfo, retrievePnrInfo, setPnrInfo: pnrContextInfo.setPnrInfo };
};

export default useCurrentPnrInfo;
