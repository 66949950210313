"use client";
import { IconChevronRight } from "@assets/icons";
import { BottomSheet } from "@components/Modals";
import { PNRField } from "@components/PNRField";
import { EventName, EventProperty } from "@enums/events";
import { ErrorMessages } from "@enums/messages";
import { PantryInfo, StationInfo, TrainInfo } from "@interfaces/index";
import Analytics from "@services/analytics";
import { Config } from "@services/utils/Config";
import Images from "@services/utils/Images";
import { toast } from "@utils/Notification";
import { useRouter } from "next/router";
import React, { useState } from "react";
import useCurrentPnrInfo from "./useCurrentPnrInfo";
import useCurrentStationInfo from "./useCurrentStationInfo";

type RenderPnrField = (arg0?: {
  className?: string;
  placeholder?: string;
  hideSubmitButton?: boolean;
  onPnrFieldFocus?: React.FocusEventHandler;
}) => JSX.Element;

type PnrFieldProps = (arg0: { isMobile: boolean; enablePantry?: boolean }) => {
  renderPnrField: RenderPnrField;
  pnr: string;
  handleRetrievePnrInfo: (pnr: string) => void;
};

type PnrFoodBookingFlowOptionDetails = {
  label: string;
  sublabel: string;
  logoSrc: string;
  labelColor?: string;
  onClick: () => void;
};

const PnrFoodBookingFlowOptionDetails = ({ label, sublabel, logoSrc, labelColor, onClick }: PnrFoodBookingFlowOptionDetails) => {
  return (
    <div className="m-4 flex cursor-pointer items-center rounded-xl border border-gray-30 p-4" onClick={() => onClick()}>
      <span className="mr-3 rounded-full">
        <img src={logoSrc} alt="Pantry Food" />
      </span>
      <span>
        <h4 className={`text-sm font-medium ${labelColor ?? "text-info-primary"}`}>{label}</h4>
        <p className=" text-xs text-gray-70">{sublabel}</p>
      </span>
      <IconChevronRight color="#f26122" />
    </div>
  );
};

const usePnrField: PnrFieldProps = ({ isMobile, enablePantry }) => {
  const [pnr, setPnr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPnrFoodBookingFlowOptions, setShowPnrFoodBookingFlowOptions] = useState(false);
  const router = useRouter();

  const { setCurrentStationInfo } = useCurrentStationInfo();
  const [validPnrResultData, setValidPnrResultData] = useState<
    { stations: StationInfo[]; pnr: string; trainInfo: TrainInfo; pantryInfo?: PantryInfo | null } | undefined
  >();
  const handlePNRInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 10) {
      setPnr(e.target.value);
    }
  };

  const invalidPnrCb = (errorMsg?: string) => {
    errorMsg && toast.notify(errorMsg);
    setIsLoading(false);
  };

  function proceedWithPantryFlow(trainInfo: TrainInfo, pantryInfo: PantryInfo) {
    router.push(
      `/pantry/${trainInfo.trainNo}/menu?pnr=${pnr}&vendorId=${pantryInfo.vendorId}&outletId=${pantryInfo.outletId}&boardingDate=${trainInfo.dt
        .split("-")
        .reverse()
        .join("-")}&deBoardingDate=${pantryInfo.deboardingDate}`
    );
  }

  function proceedWitheCateringPnrFlow(stations: StationInfo[], pnr: string) {
    if (stations.length > 0) {
      router.push(`/${pnr}/outlets`);
      if (!isMobile) {
        setCurrentStationInfo(stations[0]);
      }
    } else {
      invalidPnrCb(ErrorMessages.NO_STATIONS_FOUND);
    }
  }

  const validPnrCb = (stations: StationInfo[], pnr: string, trainInfo: TrainInfo, pantryInfo: PantryInfo) => {
    Analytics.track(EventName.SEARCH);
    setValidPnrResultData({ stations, pnr, trainInfo, pantryInfo });
    if (pantryInfo?.isPantryAvailable && (Config.isPantryEnabled || enablePantry)) {
      Analytics.track(EventName.PANTRY_MODAL_SHOWN, { [EventProperty.TRAIN_NUMBER]: trainInfo.trainNo });
      setShowPnrFoodBookingFlowOptions(true);
    } else {
      proceedWitheCateringPnrFlow(stations, pnr);
    }
  };

  const handleRetrievePnrInfo = (pnr: string) => {
    // if (BrowserInfo?.phonepeEnv) {
    //   invalidPnrCb("Please download the Food on Track app or visit ecatering.irctc.co.in from your browser to place your order");
    // } else {
    setIsLoading(true);
    setPnr(pnr);
    retrievePnrInfo(pnr);
    // }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (pnr.length === 10) {
      handleRetrievePnrInfo(pnr);
    } else invalidPnrCb("Invalid PNR. Please enter a valid PNR");
  };

  const { retrievePnrInfo } = useCurrentPnrInfo({
    invalidPnrCb,
    validPnrCb,
  });

  const renderPnrField: RenderPnrField = ({ className = "", placeholder = "Enter PNR to order", hideSubmitButton = false, onPnrFieldFocus } = {}) => {
    return (
      <>
        <PNRField
          className={`rounded px-4 ${className}`}
          inputClassName={!isMobile ? "pl-9" : ""}
          placeholder={placeholder}
          hideSubmitButton={hideSubmitButton}
          pnr={pnr}
          handlePNRInput={handlePNRInput}
          isMobile={isMobile}
          isLoading={isLoading}
          handleSubmit={handleSubmit}
          onPnrFieldFocus={onPnrFieldFocus}
        />
        <BottomSheet
          title=""
          subtitle=""
          show={showPnrFoodBookingFlowOptions}
          toggleClose={() => {
            setShowPnrFoodBookingFlowOptions(false);
            setIsLoading(false);
          }}
          outerBoxClassName={`${isMobile ? "w-full" : "w-96"} pb-4 max-h-80 z-30 rounded-t-lg pt-4 pb-6`}
          styleForMenu={true}
        >
          <PnrFoodBookingFlowOptionDetails
            onClick={() => {
              Analytics.track(EventName.PANTRY_OPTION_TAPPED, { [EventProperty.TRAIN_NUMBER]: validPnrResultData?.trainInfo?.trainNo });
              proceedWithPantryFlow(validPnrResultData?.trainInfo as TrainInfo, validPnrResultData?.pantryInfo as PantryInfo);
            }}
            {...{ label: "Book Pantry Food", sublabel: "Delivered from train pantry onboard your train", logoSrc: Images.pantryLogo(64) }}
          />
          <PnrFoodBookingFlowOptionDetails
            onClick={() => {
              Analytics.track(EventName.PANTRY_ECAT_OPTION_TAPPED, { [EventProperty.TRAIN_NUMBER]: validPnrResultData?.trainInfo?.trainNo });
              proceedWitheCateringPnrFlow(validPnrResultData?.stations as StationInfo[], pnr);
            }}
            {...{
              label: "Order from Restaurants",
              sublabel: "Delivered from the widest variety of popular brands & restaurants",
              logoSrc: Images.eCateringLogo,
              labelColor: "text-brand-primary",
            }}
          />
        </BottomSheet>
      </>
    );
  };

  return {
    renderPnrField,
    pnr,
    handleRetrievePnrInfo,
  };
};

export default usePnrField;
