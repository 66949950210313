import Cookie from "js-cookie";
import { useEffect, useState } from "react";
import { Storage } from "@services/utils/Storage";
import { CaptchaGet, Get } from "@services/utils/Request";

type DataFlags = {
  cookieFlag?: string;
  storageKey?: string;
  storageTtl?: number;
};

type DataStore = {
  data?: any;
} & DataFlags;

type ServerClientData = {
  isServerDataAvailable?: boolean;
  serverData?: any;
  checkDataAv?: (data: any) => boolean;
} & DataFlags;

type ServerClientHookData = {
  url: string;
  getWithCaptcha?: boolean;
} & ServerClientData;

const defaultTtl = 25 * 60 * 60 * 1000;
const saveData = (key: string, data: any, ttl?: number) => {
  Storage.set(key, data, ttl ?? defaultTtl);
};

const storeData = ({ cookieFlag, storageKey, storageTtl, data }: DataStore) => {
  if (!!cookieFlag && !!storageKey && !!storageTtl && process.env.NEXT_PUBLIC_SAVE_DATA === "true") {
    Cookie.set(cookieFlag, Date.now().toString(), {
      secure: process.env.NEXT_PUBLIC_ENV !== "local",
      path: "/",
      expires: new Date(Date.now() + storageTtl),
    });
    storageKey && saveData(storageKey, data, storageTtl);
  }
};

export const handleServerClientData = ({
  isServerDataAvailable = false,
  serverData,
  cookieFlag,
  storageKey,
  storageTtl = defaultTtl,
  checkDataAv = (data: any) => !!data && Object.keys(data).length > 0,
}: ServerClientData) => {
  if (process.env.NEXT_PUBLIC_SAVE_DATA !== "true") {
    return {
      status: "failure",
      result: {},
      message: "Disabled",
    };
  }

  if (isServerDataAvailable && !!cookieFlag) {
    storeData({ cookieFlag, storageKey, storageTtl, data: serverData.result });
    return { status: "success", result: {}, message: "Saved" };
  } else {
    const storageData = storageKey ? Storage.get(storageKey) : null;
    console.log("handleServerClientData storageData", storageData);
    if (checkDataAv(storageData)) {
      return { status: "success", result: storageData, message: "" };
    }
  }
  return {
    status: "failure",
    result: {},
    message: "Not found",
  };
};

const useServerClientData = ({
  isServerDataAvailable,
  serverData,
  url,
  cookieFlag,
  storageKey,
  storageTtl,
  getWithCaptcha = false,
  checkDataAv = (data) => !!data && Object.keys(data).length > 0,
}: ServerClientHookData) => {
  console.log("serverData", isServerDataAvailable, serverData);
  const [data, setData] = useState(isServerDataAvailable ? serverData.result : undefined);

  useEffect(() => {
    const serverClientData = handleServerClientData({ serverData, isServerDataAvailable, cookieFlag, storageKey, storageTtl, checkDataAv });

    if (!isServerDataAvailable) {
      if (serverClientData?.status === "success") {
        setData(serverClientData.result);
      } else {
        (getWithCaptcha ? CaptchaGet : Get)({ url }).then((res: any) => {
          if (checkDataAv(res?.result)) {
            setData(res.result);
            storeData({ cookieFlag, storageKey, storageTtl, data: res.result });
          }
        });
      }
    }
  }, []);

  return [data];
};

export default useServerClientData;
