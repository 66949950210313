import React from "react";
import Loader from "./Common/Loader";

const FullScreenLoader: React.FC<{ show: boolean; children: React.ReactNode }> = ({ show, children }) => {
  return show ? (
    <div className={`fixed inset-0 z-20 h-screen w-screen bg-white bg-opacity-80`}>
      <div className={`absolute top-1/2 right-1/2 flex translate-x-1/2 -translate-y-1/2 transform flex-col items-center `}>
        <div className="scale-50 transform">
          <Loader />
        </div>
        {children}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default FullScreenLoader;
