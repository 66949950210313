import * as Sentry from "@sentry/nextjs";

const logError = (error: any) => {
  console.error(error);
  if (process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN) {
    Sentry.captureException(error);
  }
};

export const logger = {
  error: logError,
  info: console.info,
};
