import { IconTrain, Loader } from "@assets/icons";
import Input from "@components/Common/input";
import { ElementId } from "@enums/common";
import React from "react";

type PnrFieldProps = {
  className?: string;
  placeholder: string;
  isMobile: boolean;
  hideSubmitButton?: boolean;
  isLoading?: boolean;
  pnr: string;
  handlePNRInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: React.FormEvent) => void;
  submitBtnText?: string;
  inputClassName?: string;
  onPnrFieldFocus?: React.FocusEventHandler;
  autoFocus?: boolean;
};

export const PNRField: React.FC<PnrFieldProps> = ({
  className = "",
  placeholder,
  isMobile,
  hideSubmitButton = false,
  isLoading = false,
  pnr,
  handlePNRInput,
  handleSubmit,
  submitBtnText = "Submit",
  inputClassName = "",
  onPnrFieldFocus,
  autoFocus,
}) => {
  return (
    <form
      className={`relative flex items-center justify-between bg-white  ${!className.includes("shadow") ? "shadow-6dp" : ""} ${className}`}
      onSubmit={handleSubmit}
    >
      <IconTrain className={`active absolute left-2 ${isMobile ? "" : "text-2.5xl"} `} color={"#aaa"} />
      <Input
        className={`w-12/12 flex-1 pl-8 text-lg tracking-wide focus:outline-none ${inputClassName}`}
        type={"number"}
        value={pnr}
        name={"PNR"}
        placeholder={placeholder}
        onChange={handlePNRInput}
        id={ElementId.pnrInput}
        onFocus={onPnrFieldFocus}
        autoFocus={autoFocus}
      />
      {!hideSubmitButton && (
        <button
          // disabled={!(pnr.length === 10 || pnr.length === 0 || !isLoading)}
          disabled={isLoading}
          className={`btn-primary absolute flex items-center justify-center disabled:bg-gray-40 ${isMobile ? "right-2 w-3/12" : "right-4 w-20.5"}`}
          type={"submit"}
        >
          {isLoading ? <Loader /> : submitBtnText}
        </button>
      )}
    </form>
  );
};
