import { useMemo } from "react";
import useCommonContext from "./useCommonContext";

const useMounted = () => {
  const { mounted } = useCommonContext();

  const memoizedMounted = useMemo(() => mounted, [mounted]);

  return memoizedMounted;
};

export default useMounted;
