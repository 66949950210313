"use client";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { PnrContextType } from "../context";
import { CurrentStationInfo, SetCurrentStationInfo } from "../context/PnrContext/types";
import usePnrContext from "./usePnrContext";

type UseCurrentStationInfo = () => {
  currentStationInfo: CurrentStationInfo | null;
  setCurrentStationInfo: NonNullable<PnrContextType["setStationInfo"]>;
};

const useCurrentStationInfo: UseCurrentStationInfo = () => {
  const { query } = useRouter();

  const {
    pnrInfo: { stations },
    stationInfo: currentStationInfo,
    setStationInfo,
  } = usePnrContext();

  const setCurrentStationInfo: NonNullable<SetCurrentStationInfo> = (args) => {
    setStationInfo?.(args);
  };

  useEffect(() => {
    if (stations !== null && query.stationCode) {
      const stationInfo = stations.find((station) => station.code === query.stationCode);
      // use stations info only if url has pnr param
      if (!!stationInfo && !!query.pnr) {
        setCurrentStationInfo(stationInfo);
      }
    }
    if (!!currentStationInfo) {
      // if the date in query doesn't match with current station's date info
      // Get the date and time from url and form a parsable date time string
      if (!!query.date ? !currentStationInfo.arrival?.includes(query.date as string) && !!query.time : false) {
        const arrivalString = `${query.date} ${query.time} GMT+05:30`;
        setCurrentStationInfo?.({
          ...currentStationInfo,
          arrival: arrivalString,
        });
      }
    }
  }, [stations, query]);

  return { setCurrentStationInfo, currentStationInfo };
};

export default useCurrentStationInfo;
